.container {
  box-sizing: border-box;
  min-height: 100vh;
  /* border:2px solid green;
    max-height:90% */
}

.childrenContainer {
  min-height: 75vh !important;
}

@media only screen and (min-width: 769px) {
  .container {
    overflow-y: auto;
    background: #F9FAFC;
  }
}
