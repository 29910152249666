.header {
  box-sizing: border-box;
  padding: 0.5em 1em;
}

.title {
  flex: 1;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color:var(--headline-text);
}

.backButton {
  width: 42px;
  height: 42px;
  margin-right: 7px;
}

.actionContainer {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
}
