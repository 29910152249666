.itemRow {
  padding: 1.75rem 0.5rem;
  border-bottom: 1px solid var(--border-light);
}

.blur .itemInfo,
.blur img {
  opacity: 0.6;
}
.banner p{
  min-width: 90px;
  margin-top: 2px;
}
.itemVariant a{
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
  cursor: pointer;
}
.itemImage {
  position: relative;
  height: 4.5rem;
  width: 4.5rem;
  padding: 0.5rem;
  border: 2px solid var(--border-light);
  border-radius: 0.5rem;
  margin: auto 0.75rem auto 0;
  cursor: pointer;
}

.itemImage img {
  display: inline-block;
  min-width: 4rem !important;
  min-height: 4rem !important;
 
}

.alerzoLogo {
  width: 100%;
  height: 6rem;
  object-fit: contain;
  transform: scale(0.7);
  opacity: 0.25;
}

.itemDelete {
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  color: var(--accent-light);
  min-width: 74px;
  margin-right: 8px ;
}

.itemInfo {
  margin-right: auto;
  flex-grow: 1;
}

.itemPrice {
  margin-top: auto;
}

.itemVariant {
  font-size: 1rem;
  font-weight: 500;
  margin: 0.25rem 0;
}

.itemCategory {
  margin-top: 0;
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--accent-light);
  flex: 1;
}

.itemPrice,
.pricingSpanAlt {
  font-size: 1rem !important;
  font-weight: 500 !important;
  margin-bottom: 0;
  color: black !important;
}

.itemPrice span {
  font-size: 0.875rem;
  font-weight: 500;
  text-decoration-line: line-through;
  color: var(--accent-light);
}

.promotionalPricing {
  margin-bottom: 1rem;
}

.priceInactive {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--accent-light);
  font-size: 0.71rem;
}

.price {
  font-weight: 500;
  color: var(--accent);
  font-size: 0.875rem;
}

.priceAlt {
  text-decoration: line-through;
  margin-bottom: 8px;
}

.totalPrice {
  font-weight: 500;
  color: var(--success);
}

.pricing {
  margin-bottom: 0.75rem !important;
  margin-top: auto;
  font-size: 1rem !important;
}

.pricingSpan,
.pricingSpanAlt {
  text-decoration-line: none !important;
}

.pricingSpan span,
.pricingSpanAlt span {
  text-decoration-line: none !important;
  font-size: 12px !important;
}

.itemTotal {
  font-size: 1rem;
  margin-bottom: 0;
  font-weight: 500;
  color: var(--success);
  margin-top: auto;
  flex-grow: 1;
  text-align: right;
}
.itemControl{
  margin-left: auto;
}

.itemController {
  width: 8rem;
}

.itemIndicator {
  font-size: 0.875rem;
  font-weight: 500;
  margin: 0;
  color: var(--success);
}

.itemQuantity {
  font-weight: 500;
  font-size: 1rem;
  color: var(--accent);
  flex-grow: 1;
  text-align: right;
}

.outofStockContainer{
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: 12px;
}

.wholeSalePriceActive,
.wholeSalePriceInactive {
  display: block;
  padding: 6px !important;
  border-radius: 6px;
  font-size: 10px;
  margin: 0 !important;
  margin-right: 8px !important;
  text-align: center;
}

.wholeSalePriceActive {
  background: #e0f9e3;
  color: #028c43;
}

.wholeSalePriceInactive {
  background: #fff4c1;
  color: #b34c02;
}


.wholeSalePriceActive {
  padding: 2px 3px;
  font-size: 13px;
  line-height: 18px;
  background: #E9FBF2;
  color: var(--success);
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.wholeSalePriceActive span{
  padding-right: 2px;
}

.wholesaleIcon{
  padding: 0 3px;
}

.wholeSalePriceInactive {
 font-size: 13px;
 line-height: 18px;
 color: var(--disabled-text);
 flex-wrap: wrap;
}

.wholeSalePriceInactive span{
  color: var(--success);
}

.pricingRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px;
}

.bundleIcon {
  padding: 0px;
  /* padding-right: 6px; */
  /* border-radius: 5px; */
}

.info {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--accent-light);
  font-size: 0.71rem;
}

.notInStock {
  font-size: 0.7rem;
  color: #f44336;
  position: relative;
  white-space: nowrap;
  margin-right: 0.8rem;
  max-width: 6rem;
  padding: 6px !important;
  border-radius: 6px;
  background: #feebeb;
  text-align: center;
}

.bundleIconContainer {
  display: flex;
  align-items: center;
}

.bundleIconContainer span {
  color: #02559e;
  padding-left: 5px;
  font-size: 0.9em;
}

.sellerControls {
  display: flex;
  align-items: center;
}

.discountMarker {
  background: #4daf7b;
  color: #ffffff;
  padding: 3px;
  border-radius: 100px;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: 8px;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
  align-self: flex-start;
  justify-content: center;
}

.regularPriceMarker {
  background: #FFB207;
  color: #ffffff;
  padding: 3px;
  border-radius: 100px;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: 8px;
  font-size: 10px;
  font-weight: bold;
}

.promotedPriceMarker {
  background: rgba(255, 178, 7, 0.5);
  border-radius: 6px;
  padding: 3px 5px;
  font-size: 10px;
  line-height: 16px;
  color:var(--headline-text);
  font-weight: 800;
  margin-right: 8px;
  text-align: center;
  min-width: 73px;
}

@media only screen and (max-width: 400px) {
  .discountMarker{
    max-width: 5rem;
  }
}


@media only screen and (min-width: 769px) {
  .itemRow {
    padding: 1.75rem 0;
    position: relative;
  }
  .itemRow:last-of-type{
    border-bottom: none;
  }
}
