
.paper{
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 13px 56px rgba(136, 122, 166, 0.12);
    border-radius: 12px;
    padding: 16px;
    min-width: 250px;
    min-height: 165px;
}

.crossButton{
    margin-top: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 5px 16px -4px rgba(29, 28, 28, 0.1);
    padding: 0.875rem;
    border-radius: 50%;
}