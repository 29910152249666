.container {
  flex: 9;
  padding-right: 15px;
  padding-left: 10px;
  z-index: 999;
}

.profile {
  padding: 1rem 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.avatar {
  width: 5rem;
  height: 5rem;
  box-sizing: border-box;
  border: 1px solid #dce0eb;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alerzoLogo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform: scale(0.6);
  opacity: 0.25;
}

.name {
  font-size: 1rem;
  font-weight: bold;
  color: var(--accent);
  margin-bottom: 0;
  line-height: 1rem;
}

.info {
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--accent-medium);
  margin-bottom: 0.5rem;
}

.location {
  font-size: 1rem;
  font-weight: 400;
  color: var(--accent-medium);
  line-height: 0.25rem;
}

.menuList {
  padding: 0.5em 0;
  background: white;
}

.menuHeading {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1.25rem;
  color: var(--accent-light);
}

.menuGroup {
  width: 100%;
  margin: 0;
  padding: 1em 0em 1em 0em;

  padding-bottom: 0.5rem;
  list-style-type: none;
  border-bottom: 1px solid var(--border-light);
}

.menuItem {
  cursor: pointer;
}

.menuGroup > .menuItem:last-child {
  margin-bottom: 0;
  padding-bottom: 1em;
}

.actionGroup {
  background-color: white;
  padding: 1em 1em 3em 1em;
}

.button {
  border: none;
  background-color: white;
  width: fit-content;
  font-size: 1rem;
  text-transform: none;
  padding: 0;
  color: var(--accent-light);
  font-family: inherit;
  text-decoration: none;
  margin-top: 1em;
  cursor: pointer;
}

.actionGroup > button.button {
  margin: 1em 0 2em 0;
}

.version {
  color: #a5adba;
  font-size: 0.75rem;
}

.signIn {
  font-size: 1rem;
  color: #00559e;
  font-weight: bold;
  margin-top: 1rem;
}

.register {
  font-size: 0.875rem;
  margin-top: 1rem;
}

.registerLink {
  margin-left: 4px;
  color: #00559e;
  font-weight: bold;
  cursor: pointer;
}

.dropdownButton {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.dropdownButton p {
  margin: 0px;
  flex: 11;
  text-align: right;
  padding-top: 5px;
}

.userIcon {
  margin-left: 10px;
}

/* .dropdownIcon, .dropdownIconReversed{
  padding-bottom:5px
} */

.dropdownIconReversed {
  transform: rotate(180deg);
}

.businessName {
  font-size: 0.9em;
}

.buttonContainer {
  width: 100%;
  cursor: pointer;
}

.actionButton {
  margin: 1.5rem 0 !important;
  padding: 0.875rem 0 !important;
  font-weight: 500 !important;
  width: 100% !important;
}

.dropdownContainer {
  min-width: 13rem;
}
