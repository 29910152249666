.container {
  border: none;
  background: #E2EEFF;
  border-radius: 6px;
  padding: 8px 10px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  font-size: 15px;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: #1A51A3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.container span{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.similarItemsContainer{
  height: 2rem;
  min-width: 8rem;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  justify-content: center;
}
 
@media only screen and (max-width: 768px) {
  .largeScreenOnly {
    display: none !important;
  }
  .smallScreenOnly {
    display: initial !important;
  }
}

@media only screen and (min-width: 769px) {
  .smallScreenOnly {
    display: none !important;
  }
  .largeScreenOnly {
    display: initial !important;
  }
}
