.menu {
    margin-bottom: 1.5rem;
}

.label {
    font-size: 1rem;
    font-weight: 400;
    color: var(--accent);
    margin: 0 1.5rem;
}

.link {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.subText {
    margin: 0 1rem;
    text-transform: capitalize;
    
}
