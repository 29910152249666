.resetCss img, .resetCss p {
  opacity: 1 !important;
}

.vsiPaper{
  height: 436px;
  width: 90vw;
  padding: 16px 0 0 !important;
  border-radius: 24px !important;
  background: #FEFEFF !important;
  box-shadow: 0px -10px 180px rgba(147, 147, 147, 0.24) !important;
  font-style: normal;
  font-family:'Avenir';
}

.titleBox {
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 13px 56px rgba(136, 122, 166, 0.12);
  border-radius: 12px;
  margin: 0 16px;
  display: flex;
  padding: 10px;
  flex: 1;
}

.imageSection {
  position: relative;
  min-width: 55px;
  height: 55px;
  width: 10%;
  margin-top: 2px;
}

.image {
  min-width: 55px !important;
  min-height: 55px !important;
  cursor: pointer;
}

.alerzoLogo {
  opacity: 0.35;
  margin: auto;
}

.infoSection {
  padding-left: 5px;
  flex: 1;
  overflow: hidden;
}

.title {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: var(--headline-text);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title a:any-link {
 all: unset !important;
}

.info {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  line-height: 18px;
  color: var(--disabled-text);
  margin-bottom: 2px;
}

.outOfStock {
  background: #FEECEB;
  color: #e94033;
  text-align: center;
  font-size: 13px;
  line-height: 18px;  
  border-radius: 4px;
  padding:0 4px ;
  width:fit-content;
  margin-right: 8px;
}

.itemsList {
  height: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
}

.loader {
  position: relative;
  min-height: 19.2rem;
}

.similarItemsTitle {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color:var(--headline-text);
  margin: 1.25rem 0 0.75rem 1rem;
}

.list {
  padding-top: 3rem;
  margin-top: -3rem;
  box-sizing: border-box;
  padding-bottom: 2rem;
  padding-left: 1rem;
}

.list > .product {
  width: 50%;
  max-width: 12rem;
  margin-right: 1rem;
}

.greeting {
  text-align: center;
  margin-top: 1rem;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: var(--headline-text);
}

.notifyButtonAlt{
  margin: -20px 16px auto 16px;
  justify-content: center;
  background: var(--alerzo-blue) !important;
  border-radius: 10px;
}

.notifyButtonAlt p{
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

.notifyButtonAlt path{
  fill: #FFFFFF !important;
}

.notifyTextAlt{
 margin: -20px auto auto auto;
}

@media only screen and (max-width: 768px) {
  
  .largeScreenOnly {
    display: none !important;
  }
  .smallScreenOnly {
    display: initial !important;
  }

}

@media only screen and (min-width: 769px) {
  .smallScreenOnly {
    display: none !important;
  }
  .largeScreenOnly {
    display: initial !important;
  }
 
  .dialogContentAlt  {
    padding: 0 !important;
  }
  
  .vsiPaper{
    max-width: 470px !important;
    border-radius: 12px !important;
    background: var(--desktop-background) !important;
  }

  .backBtn {
    width: 20px;
    height: 20px;
    margin-right: 16px;
    background: var(--alerzo-blue);
  }

  .backBtn svg{
    width: 8px;
    height: 8px;
  }
  
  .backBtn path{
    fill: #FFFFFF !important;
  }

}



