.container {
  background: #ffffff;
  display: flex;
  /* overflow: hidden; */
  border-radius: 5px;
  margin-left: 30px;
  flex: 5;
  position: relative;
  z-index: inherit;
  /* width:40% */
}

.inputContainer {
  border: none;
  display: flex;
  align-items: center;
  flex: 11;
  padding: 6px;
}

.input {
  border: none;
  flex: 1;
  padding: 5px;
}

.input:focus {
  border: none !important;
  outline: none !important;
}

.searchIconContainer {
  background: #e4eef5;
  flex: 1;
  max-width: 35px;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #d9e5f0;
  cursor: pointer;
  border-radius: 0 5px 5px 0px;
}

.searchContainer {
  /* width: 100%; */
  min-width: 20rem;
  position: absolute;
  top: 105%;
  left: 0px;
  right: 0px;
  z-index: 50000;
  background: #ffffff;
  min-height: 5rem;
  border-radius: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  max-height: 20rem;
  overflow-y: auto;
}

.dropdownContainer {
  position: relative;
}

.variant {
  color: black;
  margin: 0px;
  padding: 10px;
  cursor: pointer;
}

.variant:hover {
  background: #d9e5f0;
}

.chipContainer {
  margin: 0 1em 1.25em 1em;
}

.chip {
  color: var(--accent-medium) !important;
  border: 1px solid var(--border-light) !important;
  background-color: white !important;
  margin: 0 0.75em 0.75em 0 !important;
}

.headingContainer {
  margin: 0 1em 1em 1em;
  color: black;
}
