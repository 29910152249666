.container {
  flex: 3;
  padding-left: 15px;
  display: flex;
  align-items: center;
  border-left: 1px solid #ffffff;
  z-index: 999;
  /* justify-content: flex-end; */
}

.cursorDefault span,
.cursorDefault p,
.cursorDefault table tbody thead tr td {
  cursor: default !important;
}
.unAvailableItems{
  display: flex;
  align-items: center;
  padding: 0 1rem;
  margin-top: -1rem;
}


.itemDelete {
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  color:#fb7181;
  width: 6.5rem;
  
  margin-top: -1rem;
}
.dropdownText {
  margin: 0px;
  margin-right: 5px;
  /* cursor: default; */
}

.dropdownButton {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.cartContainer {
  width: 24rem;
  padding: 1rem;
}

.actionButton {
  margin: 1.5rem 10% !important;
  padding: 0.875rem 0 !important;
  font-weight: 500 !important;
  width: 80% !important;
}

.placeholderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.placeholderContainer > .button {
  margin: 1em 0 2em 0;
  width: 50%;
}

.button,
.shopBtn {
  margin: 1.5rem 1rem 3rem 1rem;
  background-color: var(--primary);
  border-radius: 0.25rem;
}

.button a {
  padding: 1rem;
  text-decoration: none;
  width: 100%;
  color: #fff;
  font-size: 1rem;
  text-align: center;
  font-weight: 500;
}

.icon {
  fill: var(--primary);
  stroke: #ffffff;
}

.cartItemContainer {
  max-height: 18rem;
  overflow-y: auto;
}

.summaryTitle {
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0 0 0.5rem;
}