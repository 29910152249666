.container {
  background: none;
  border-radius: 5px;
  cursor: pointer;
  width: auto;
  display: flex;
  align-items: center;
  border: none;
  z-index: 200;
  font-size: 15px;
  line-height: 10px;
  padding: 0;
}
 
.container p {
  color: #1A51A3;
  margin: 0px;
  margin-left: 2px;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
}

.notifyText {
  max-width: 100% !important;
  word-wrap: break-word !important;
  margin: 0px;
  font-weight: bold;
  overflow: hidden;
  font-family: 'Avenir';
  font-weight: 500;
  font-style: normal;
  font-size: 13px;
  line-height: 18px;
  color: var(--success);
}

.notifyIcon{
  margin-top: 2px;
}

