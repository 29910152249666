.chipContainer {
  margin: 0 1em 1.25em 1em;
}

.chip {
  color: var(--accent-medium) !important;
  border: 1px solid var(--border-light) !important;
  background-color: white !important;
  margin: 0 0.75em 0.75em 0 !important;
  
}

.icon {
  height: 1rem !important;
  width: 1rem !important;
  color: #69778C !important;
}
