.entityImage, .entityInitials {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    font-size: 2em;
    font-weight: 500;
    color: white;
    object-fit: cover;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.entityImage {
    cursor: pointer;
}

.imageButton {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    outline: none;
    background-color: transparent;
    border: none;
    padding: 0;
}

.border {
    border: 0.3rem solid var(--header);
    box-sizing: border-box;
}
