.productContainer {
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 13px 56px rgba(136, 122, 166, 0.12);
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 16px;
  position: relative;
}

.cartSense{
  border-bottom: 0.5px solid #E2E4F0;
  margin-bottom: 8px;
  padding-bottom: 8px;
}

.imageSection {
  position: relative;
  min-width: 55px;
  height: 55px;
  margin: 0;
  margin-right: 6px;
  width: 10%;
  margin-bottom: 12px;
}

.image {
  min-width: 55px !important;
  min-height: 55px !important;
  cursor: pointer;
}

.alerzoLogo {
  opacity: 0.35;
  margin: auto;
}

.infoSection {
  width: 50%;
}

.title {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: var(--headline-text);
  /* white-space: nowrap; */
  /* intentional comments */
  overflow: hidden;
  text-overflow: ellipsis;
}

.title a {
  color: inherit;
  text-decoration: inherit; 
  cursor: pointer;
}

.info {
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  line-height: 18px;
  color: var(--disabled-text);
  margin-bottom: 0.25rem !important;
}

.pricing {
  margin-bottom: 8px;
}

.activePrice {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: var(--headline-text)
}

.inactivePrice {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: var(--accent-light);
}

.infoAlt {
  white-space: nowrap;
  max-width: 10rem;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: var(--disabled-text);
}

.outOfStock {
  background: #FEECEB;
  color: #e94033;
  text-align: center;
  font-size: 13px;
  line-height: 18px;  
  border-radius: 4px;
  padding:0 5px ;
  width: 74px;
  height: 18px;
  margin-bottom: 8px;
}

.wholesaleLogin{
  width: 100px;
  height: 20px;
}

.retailLogin{
  width: 50px;
  height: 20px;
}

.quantitySection {
  width: 40%;
  overflow: hidden;
  flex: 1 1 36%;
  margin-top: auto;
  max-width: 8rem !important;
  margin-left: auto;
}

.loginButtonContainer{
  position: absolute;
  right: 8px;
  bottom : 12px
  
}

.quantityControlSize{
  min-width: 2rem !important;
}

.totalPrice {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--success);
  margin-left: auto;
  min-width: fit-content
}

.totalPriceAlt{
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--alerzo-blue);
  margin-left: auto;
  min-width: fit-content
}

.wholeSalePriceActive {
  padding: 2px 3px;
  font-size: 13px;
  line-height: 18px;
  background: #E9FBF2;
  color: var(--success);
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.wholeSalePriceActive span{
  padding-right: 2px;
}

.wholesaleIcon{
  padding: 0 3px;
}

.wholeSalePriceInactive {
 font-size: 13px;
 line-height: 18px;
 color: var(--disabled-text);
 flex-wrap: wrap;
 margin: 6px 2px;
}

.wholeSalePriceInactive span{
  color: var(--success);
}

.notifyButton{
  margin-left: auto;
}
@media only screen and (max-width: 400px) {
  .infoAlt{
     font-size: 11px;
  }

  .quantityControlSize button{
   font-size: 13px;
  }

  .loginButton{
    font-size: 12px !important;
    padding: 4px 8px !important;
  }
}

@media only screen and (min-width: 400px) {
  .loginButton{
    font-size: 14px !important;
    padding: 4px 8px !important;
  }
}

.topBanner {
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 100;
}

.topBanner span{
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #434343;
  margin: 0;
}

.lockIconContainer {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  padding: 5px;
  background: #E2E4F0;
  display: flex;
  margin-right: 5px;
}

.lockIcon {
  margin:auto;
}

.containerBottom {
  display: flex;
  align-items: center;
  margin-top: 10px;
  /* padding: 10px; */
  justify-content: flex-end;
}

.lockedInput {
  display: flex;
  border: 1px solid #c3c9d1;
  border-radius: 3px;
  min-width: 100px;
  overflow: hidden;
  margin-right: 12px;
}

.unlockedInput {
  max-width: 50%;
}

.lockedInputLeft {
  padding: 5px;
  background: #c3c9d1;
  flex: 2;
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lockedInputRight {
  padding: 5px;
  background: #ffffff;
  flex: 10;
  color: #c3c9d1;
}

.bannerContainer {
  flex: 8;
}

.productColOne {
  position: relative;
  min-width: 5.5rem;
  height: 6.5rem;
  /* padding: 0.5rem; */
  margin-right: 1rem;
}

.productColTwo {
  flex: 7.5;
}

.productDetailsRow {
  display: flex;
  align-items: center;
}



.infoAlt {
  white-space: nowrap;
  max-width: 10rem;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--accent-light);
  font-size: 10px;
}

.discountMarker {
  color: #ffffff;
  margin-right: 8px;
  padding: 3px 5px;
  background: rgba(0, 149, 75, 0.8);
  border-radius: 6px;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
}

.regularPriceMarker {
  background: rgba(255, 178, 7, 0.5);
  border-radius: 6px;
  padding: 3px 5px;
  font-size: 10px;
  line-height: 16px;
  color:var(--headline-text);
  font-weight: 800;
  max-width: fit-content;
  margin-right: 8px;
}

.promotedPriceMarker {
  background: rgba(255, 178, 7, 0.5);
  border-radius: 6px;
  padding: 3px 5px;
  font-size: 10px;
  line-height: 16px;
  color:var(--headline-text);
  font-weight: 800;
  margin-right: 8px;
}


.promotionalPricing {
  margin-bottom: 1rem;
}


.priceAlt {
  font-weight: 500;
  font-size: 12px;
  color: var(--accent-light);
  text-decoration: line-through;
  margin-left: 8px;
}

.secondPrice {
  margin-left: 8px;
}

.itemTotal {
  font-size: 1rem;
  margin-bottom: 0;
  font-weight: 500;
  color: var(--success);
  margin-top: auto;
  flex-grow: 1;
  text-align: right;
}

.pricingRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
