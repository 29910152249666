.productContainer {
  background-color: #ffffff;
  font-size: 0.875rem;
  padding: 1rem ;
  box-sizing: border-box;
  position: relative;
  min-width: 11rem;
  /* margin: 1em 0.5em 0.5em 1em; */
  box-shadow: 0px 13px 56px rgba(136, 122, 166, 0.12);
  border-radius: 12px;
}

.productContainer > .item:not(:last-child) {
  margin-bottom: 0.25em;
}

.itemsLeftRibbon {
    z-index: 1;
    background: rgba(255, 17, 0, 0.5);
    border-radius: 6px;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3px;
    padding: 3px 5px;
    min-width: 62.33px;
    height: 22px;
    position: absolute;
    left: 9px;
    top: 8px;
  }

.alerzoLogo {
  transform: scale(0.6);
  opacity: 0.25;
}

.skLogoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.greyFirstHalf{
  background: #EDEDED;
  margin: -1rem -1rem 1rem -1rem;
  border-radius: 12px 12px 0px 0px;
}

.pinkFirstHalf{
  background: #FFECEE;
  margin: -1rem -1rem 1rem -1rem;
  border-radius: 12px 12px 0px 0px;
}

.greenFirstHalf{
  background: #D5EFE2;
  margin: -1rem -1rem 1rem -1rem;
  border-radius: 12px 12px 0px 0px;
}

.image {
  width: 100% !important;
  margin-top: 1.5rem !important;
  height: 5rem !important;
  min-height: auto !important;
  object-fit: contain;
  margin-bottom: 0.75em !important;
}
.title {
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--accent);
}
 
.title a {
    color: inherit; 
    text-decoration: inherit; 
    cursor: pointer;
}

.info {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--accent-light);
  margin-bottom: 0.25rem !important;
  font-size: 0.71rem;
}

.priceInactive {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--accent-light);
  font-size: 0.71rem;
}

.pricing {
  margin-bottom: 0.75rem !important;
  margin-top: auto;
}

.pricing p{
  padding: 0 !important;
}

.cutOutPrice {
  display: none;
  font-size: 0.71rem;
  color: var(--accent-light);
  text-decoration-line: line-through;
  margin-bottom: 0.25rem;
}

.price {
  font-weight: 500;
  color: #02559e;
  font-size: 0.875rem;
}

.totalPrice {
  font-weight: 500;
  color: var(--success);
}

.show {
  display: inline;
}

.addToCartButton {
  height: 1.75rem;
  line-height: 1;
  font-size: 0.75rem !important;
  padding: 0.25em 1em !important;
}

.imageContainer {
  position: relative !important;
  min-height: 7.4rem;
  cursor: pointer;
}

.wholeSalePriceActive,
.wholeSalePriceInactive {
  padding: 6px !important;
  border-radius: 6px;
  margin: 0px 0px 10px 0px !important;
  font-size: 10px;
  position: absolute;
  bottom: -5px;
  right: 5px;
  left: 5px;
  text-align: center;
}

.outOfStock {
  background: #FEECEB;
  color: #e94033;
  text-align: center;
  align-self: flex-start;
  font-family: 'Avenir';
  font-size: 13px;
  line-height: 1;
  position: absolute;
  top: 90px !important;
  right: 5px !important;
  z-index: 100;
  padding: 3px;
  border-radius: 4px;
 
}

.wholeSalePriceActive {
  background: #e0f9e3;
  color: #028c43;
}

.wholeSalePriceInactive {
  background: #fff4c1;
  color: #b34c02;
}

.notInStock {
  font-size: 0.8rem;
  color: #f44336;
  position: relative;
  white-space: nowrap;
}

.bundleIcon {
  position: absolute;
  top: 90px !important;
  right: 5px !important;
  z-index: 100;
  padding: 3px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.5);
}

.bundleIconContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

 .bundleIconContainer span {
    padding-left: 3px;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 1;
    text-align: right;
    color: #434343;
   }

.bannerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


@media only screen and (max-width: 388px) {
  .notifyTextAlt{
    font-size: 11px !important;
  }
  .notifyButtonAlt p{
    font-size: 13px !important;
  }
}
@media only screen and (max-width: 398px) {
  .viewSimilarButtonAlt{
    font-size: 13px !important;
  }
  .loginButton{
    font-size: 12px !important;
  }
}