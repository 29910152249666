.summaryTable {
  padding: 0.5rem !important;
  /* background: white; */
  margin-top: 1rem;
}

.summaryRow {
  border: none !important;
}

.summaryHead {
  padding: 0.5em 1em !important;
  font-size: 0.875rem !important;
  color: var(--accent-light) !important;
}

.summaryValue {
  padding: 0 1em !important;
  font-size: 0.875rem !important;
  color: var(--accent) !important;
}

.summaryTotal {
  border-top: 1px solid var(--border-light) !important;
  border-bottom: 1px solid var(--border-light) !important;
}

.summaryTotalValue {
  font-size: 1rem !important;
  font-weight: 500 !important;
  color: var(--accent) !important;
}

.summaryTotalValue span {
  font-size: 0.75rem !important;
  margin-left: 0.5rem;
  font-weight: 400 !important;
  color: var(--accent-light) !important;
}

.couponName {
  color: var(--success) !important;
  text-decoration: underline;
  text-decoration-style: dashed;
}

.couponDiscount {
  color: var(--success) !important;
}

.summaryValue a{
font-style: normal;
font-weight: 800;
font-size: 1rem;
line-height: 1.375rem;
color: #00559E;
text-decoration: none;
}