.backButton {
  cursor: pointer;
}

.header {
  padding: 1rem;
  border-bottom: 1px solid var(--border-light);
  box-sizing: border-box;
}

.title {
  margin: 0;
  font-size: 1.05rem;
  font-weight: 500;
  color: var(--accent);
  flex: 1;
  padding-right: 1rem;
  text-align: center;
}

.row {
  width: 100%;
  padding: 1.25rem 0;
  border-bottom: 1px solid var(--border-light);
}

.SearchField {
  min-width: 20.65rem;
  border-radius: 1.5rem;
  padding: 0 1rem;
  background: rgba(105, 119, 140, 0.08);
}

.location {
  /* width: 100%; */
  padding: 1.25rem 1.25rem;
  border-bottom: 3px solid var(--border-light);
}

.locationContainer {
  padding: 1.25rem 1.25rem;
  /* border-bottom: 3px solid var(--border-light); */
}

.locationIcon {
  margin-left: 0.25rem;
  color: var(--primary);
}

.locationButton {
  margin-left: 0.25rem;
  border: none;
  outline: none;
  background: none;
  color: var(--primary);
  font-size: 1rem;
  font-weight: 500;
}

.resultGroup {
  padding: 1rem;
  max-height: 200px;
  overflow-y: auto;
}

.result {
  width: 100%;
  border: none;
  outline: none;
  background: none;
  padding: 1rem 0;
  border-bottom: 2px solid var(--border-light);
}

.result svg {
  flex-shrink: 0;
}

.recentLocation {
  font-size: 0.75rem;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: var(--accent-light);
}

.resultName {
  font-weight: 500;
  font-size: 1rem;
  margin: 0 0.5rem 0.5rem 0.5rem;
  color: var(--accent);
  text-align: start;
}

.resultAddress {
  font-weight: 400;
  font-size: 0.875rem;
  color: var(--accent-light);
  margin: 0 0.5rem;
  text-align: start;
}

.backButton {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed !important;
  width: 32px;
  height: 32px;
  left: 15px !important;
  top: 15px !important;
  background: #ffffff;
  z-index: 2 !important;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.backButton > svg {
  fill: #69778c;
}

.ConfirmationBox {
  min-height: 138px;
  width: calc(100% - 30px);
  position: fixed !important;
  bottom: 0 !important;
  z-index: 2;
  background: #ffffff;
  box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.06);
  padding: 25px 15px 25px 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.ConfirmationBox1{
  min-height: 138px;
  width: calc(100% - 30px);
  /* position: fixed !important; */
  bottom: 0 !important;
  z-index: 2;
  background: #ffffff;
  box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.06);
  padding: 25px 15px 25px 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.title {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #69778c;
  text-align: left;
  flex-grow: 0;
  margin-bottom: 12px;
}
.storeNameContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
}
.storeName {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #000000;
  text-align: left;
  margin-left: 8px;
}
.address {
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: #3e4659;
  margin-bottom: 25px;
  word-wrap: break-word;
}
.ConfirmationBox > button {
  height: 48px;
  width: 100%;
}
.ConfirmationBox1 > button {
  height: 48px;
  width: 100%;
}
/* .fullHeight
 { height: 100vh;} 
 */

.hrOrContainer {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.hrBorder {
  height: 0px;
  border-bottom: 2px solid #dde0eb;
  flex: 5.5;
  width: 100%;
}

.hrOr {
  margin: 0px;
  flex: 1;
  text-align: center;
  color: #69778c;
}

.stateCityContainer {
  padding: 0px 25px;
}

@media only screen and (max-width: 768px) {
  .background {
    background-color: white;
  }

  .header {
    background-color: white;
  }
}

@media only screen and (min-width: 769px) {
  .header * {
    background-color: none !important;
    text-align: left;
  }

  .ConfirmationBox {
    min-height: 138px;
    /* width: 100%; */
    position: initial !important;
    bottom: 0 !important;
    z-index: 2;
    background: #ffffff;
    box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.06);
    padding: 25px 15px 25px 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .ConfirmationBox1 {
    min-height: 138px;
    /* width: 100%; */
    /* position: initial !important; */
    bottom: 0 !important;
    /* z-index: 2; */
    background: #ffffff;
    box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.06);
    padding: 25px 15px 25px 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
