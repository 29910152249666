.topFooter {
  background: #001833;
  color: #ffffff;
  padding: 30px;
  display: flex;
  max-width: 98vw;
  font-family: Avenir;
  overflow: scroll;
}

.bottomFooter {
  background: #192F47;
  color: #ffffff;
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  max-width: 98vw;
  font-family: Avenir;
}

.container {
  position: relative;
  min-width: 100vw;
}

.scrollToTop {
  position: absolute;
  top: -17.5px;
  right: 35px;
  height: 35px;
  width: 35px;
  background: #ffffff;
  box-shadow: 0 -4px 24px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  z-index: 1000;
  cursor: pointer;
}

.scrollToTop svg{
fill: #001833;
}

.footerCol,
.footerMidCol {
  padding: 20px;
  flex: 4;
}

.footerRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.footerMidCol {
  border-left: 1px solid #939dac;
  border-right: 1px solid #939dac;
  display: flex;
  align-items: flex-start;
}

.footerMiniCol {
  flex: 4;
  margin: 0 30px;
}

.footerMiniCol h5 {
  cursor: default;
}
.footerMiniCol h5,.footerMidCol h5{
  margin-top: 0;
}

.iconTextLink {
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}

.iconTextLink p {
  margin: 0px;
  margin-left: 5px;
}

.iconLinkContainer {
  display: flex;
}
.iconLink {
  margin-right: 10px;
  cursor: pointer;
}


.weAcceptButton {
  padding: 6px;
  border: 1px solid #939dac;
  border-radius: 5px;
}

.weAccept,
.weAcceptButton {
  display: flex;
  align-items: center;
}

.weAcceptTitle,
.weAcceptButton p {
  margin: 0px;
}

.weAcceptTitle,
.weAcceptButton {
  margin-right: 10px;
}

.weAcceptButtonIcon {
  margin-right: 8px;
}

.iconLinkTag {
  text-decoration: none;
  color: #ffffff;
}

.miniFooter {
  background: #ebecf0;
  border-top: 1.5px solid #dde0eb;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  color: #3e4659;
}
.showPointer {
  cursor: pointer;
}

.miniFooter {
  background: #ebecf0;
  border-top: 1.5px solid #dde0eb;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  color: #3e4659;
  min-width: 98vw;
}

.miniFooterLinks a {
  text-decoration: none;
  color: black;
}


@media only screen and (max-width: 768px) {
  .container,
  .miniFooter {
    display: none !important;
  }
}

