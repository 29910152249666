.container {
  position: relative;
  min-width: 100px;
}

.currentValue {
  border: 1px solid #c4c4c4;
  padding: 12px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.placeholder {
  color: gray !important;
}

.dropdownContainer {
  min-width: 150px;
  position: absolute;
  top: 105%;
  /* left: 0px; */
  right: 0px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  background: #ffffff;
  border-radius: 5px;
  z-index: 1000;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  /* overflow-y: auto; */
}

.values {
  max-height: 280px;
  overflow-y: auto;
  flex: 11;
}

.selectedValue {
  background: #cbdcec;
}

.selectedValue,
.normalValue {
  padding: 5px;
  cursor: pointer;
  margin: 0px;
}

.selectedValue:hover,
.normalValue:hover {
  background: #d9e5f0;
}

.activeIcon {
  -webkit-transform: scaleY(-1) !important;
  transform: scaleY(-1) !important;
}
