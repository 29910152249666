.productContainer {
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 13px 56px rgba(136, 122, 166, 0.12);
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 16px;
  position: relative;
}

.cartSense{
  border-bottom: 0.5px solid #E2E4F0;
  margin-bottom: 8px;
  padding-bottom: 8px;
}

.imageSection {
  position: relative;
  min-width: 55px;
  height: 55px;
  margin: 0;
  margin-right: 6px;
  width: 10%;
  margin-bottom: 12px;
}

.image {
  min-width: 55px !important;
  min-height: 55px !important;
  cursor: pointer;
}

.alerzoLogo {
  opacity: 0.35;
  margin: auto;
}

.infoSection {
  width: 50%;
}

.title {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: var(--headline-text);
  /* white-space: nowrap; */
  /* intentional comments */
  overflow: hidden;
  text-overflow: ellipsis;
}

.title a {
  color: inherit;
  text-decoration: inherit; 
  cursor: pointer;
}

.info {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  line-height: 18px;
  color: var(--disabled-text);
  margin-bottom: 0.25rem !important;
}

.pricing {
  margin-bottom: 8px;
}

.activePrice {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: var(--headline-text)
}

.inactivePrice {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: var(--accent-light);
}

.infoAlt {
  white-space: nowrap;
  max-width: 10rem;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: var(--disabled-text);
}

.outOfStock {
  background: #FEECEB;
  color: #e94033;
  text-align: center;
  font-size: 13px;
  line-height: 18px;  
  border-radius: 4px;
  padding:0 5px ;
  width: 74px;
  height: 18px;
  margin-bottom: 8px;
}

.wholesaleLogin{
  width: 100px;
  height: 20px;
}

.retailLogin{
  width: 50px;
  height: 20px;
}

.quantitySection {
  width: 40%;
  overflow: hidden;
  flex: 1 1 36%;
  margin-top: auto;
  max-width: 8rem !important;
  margin-left: auto;
}

.loginButtonContainer{
  position: absolute;
  right: 8px;
  bottom : 12px
  
}

.quantityControlSize{
  min-width: 2rem !important;
}

.totalPrice {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: var(--success);
  margin-left: auto;
  min-width: fit-content
}

.totalPriceAlt{
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--alerzo-blue);
  margin-left: auto;
  min-width: fit-content
}

.wholeSalePriceActive {
  padding: 2px 3px;
  font-size: 13px;
  line-height: 18px;
  background: #E9FBF2;
  color: var(--success);
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.wholeSalePriceActive span{
  padding-right: 2px;
}

.wholesaleIcon{
  padding: 0 3px;
}

.wholeSalePriceInactive {
 font-size: 13px;
 line-height: 18px;
 color: var(--disabled-text);
}

.wholeSalePriceInactive span{
  color: var(--success);
}

.notifyButton{
  margin-left: auto;
}
@media only screen and (max-width: 400px) {
  .infoAlt{
     font-size: 11px;
  }

  .quantityControlSize button{
   font-size: 13px;
  }

  .loginButton{
    font-size: 12px !important;
    padding: 4px 8px !important;
  }
}

@media only screen and (min-width: 400px) {
  .loginButton{
    font-size: 14px !important;
    padding: 4px 8px !important;
  }
}

