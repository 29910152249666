.searchInput {
  /* flex: 11.5; */
  border: none;
  background: none;
  /* width: 90%; */
  margin-left: 10%;
  margin-right: 10%;
}

.searchInput .MuiOutlinedInput-root {
  border-radius: 10px !important;
}

.searchInput:focus {
  border: none;
  outline: none;
  text-decoration: none;
}

.searchContainer {
  flex: 1;
  padding: 10px;
  background-color: #ebebeb;
}
