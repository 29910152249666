.select {
  min-width: 10rem;
  color: #ffffff !important;
}

.select fieldset{
  border-color: #ffffff !important;
}
.select svg{
  fill: white !important;
}

.languageIcon {
  margin-right: 5px;
}
