.paper{
  width: 100vw;
  height: 100vh !important;
  background-color: var(--background) !important;
  font-family: 'Avenir';
  font-style: normal;
}

.paperDesktop{
  width: 40%;
  height: 100vh !important;
  background-color: var(--background) !important;
  font-family: 'Avenir';
  font-style: normal;
}

.backContainer{
  width: 42px;
  height: 42px;
  position: absolute;
  top: 12px;
  left: 8px;
  z-index: 100;
}



.dialogTitleBox {
  padding: 16px 16px 12px 16px;
}

.dialogTitleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heading {
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  color: var(--headline-text);
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 2.5em;
  width: 84%;
}

.quanityInCart{
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #69778C;
  white-space: nowrap;
}

.description{
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  margin: 0.5em 0;
  color: #69778C;
}


.dialogTitle {
  color: #69778c;
  margin: 0px;
  text-transform: uppercase;
}

.brandingContainer{
  display: flex;
  align-items: center;
  gap: 5px;
}
.alerzoIcon{
  background:rgba(26, 81, 163, 0.2);
  padding:8px 10px;
  border-radius: 50%;
  width: 20px;
  height: 24px;
}
.dialogItemsList {
  padding: 0 16px;
  box-sizing: border-box;
  width: 100%;
}
.bundleType{
font-weight: 500;
font-size: 15px;
line-height: 20px;
color: #69778C;
margin-bottom: 12px;
}
.bundleStatus{
  display: flex;
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  bottom: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 1rem;
  z-index: 200;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px -12px 64px rgba(29, 58, 88, 0.04);
  backdrop-filter: blur(10px);
  border-radius: 12px 12px 0px 0px;
}

.bundleStatus del {
 font-weight: 500;
 font-size: 16px;
 line-height: 22px;
 color: #69778C;
 text-decoration: none;
 position: relative;
}

.bundleStatus del::before {
 content: "";
 width: 85px;
 height: 12px;  
 border: 3px solid #E61D17;
 border-color:#E61D17 transparent transparent transparent;
 border-radius: 50%/15px 15px 0 0;
 position: absolute;
 top:5px;
 left:-12px;
 transform: rotate(-15deg);
}

.bundleStatus ins {
  text-decoration: none;
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  color: #252424;
}

@media only screen and (max-width: 768px) {
  .largeScreenOnly {
    display: none !important;
  }
  .smallScreenOnly {
    display: initial !important;
  }

}

@media only screen and (min-width: 769px){

  .paperDesktop{
    width: 50%;
  }

  .bundleStatus{
    width: 50%;
  }
  
  .bundleCarousel :global .mobile-container {
    height:calc(40vw * (9/16)) !important;
  }
 
  .backContainer{
    left:unset;
    right: 8px;
   
  }


}


@media only screen and (min-width: 1080px) {
  .smallScreenOnly {
    display: none !important;
  }

  .largeScreenOnly {
    display: initial !important;
  }
  .paperDesktop{
    width: 40%;
  }


 .bundleStatus{
   width: 40%;
 }
 
 .bundleCarousel :global .mobile-container {
   height:calc(32vw * (9/16)) !important;
 }

 .backContainer{
   left:unset;
   right: 8px;
  
 }
}
