.container {
  background: #02559e;
  /* color: #ffffff; */
  display: flex;
  align-items: center;
  /* margin-top: 5px; */
  flex: 2;
  z-index: 1000;
}

.dropdownContainer {
  padding: 1rem !important;
  min-width: 400px;
}

.closeBtn {
  position: absolute !important;
  top: 0.5rem;
  right: 0.5rem;
}


.cityName {
  color: #ffffff;
  margin: 0px;
  margin-left: 10px;
  text-decoration: underline #ffffff dashed;
}

.focusContainer {
  position: relative;
}

.focus {
  position: fixed;
  background: #000000;
  opacity: 0.7;
  width: 300vw;
  height: 300vh;
  top: -20vh;
  left: -20vw;
  z-index: 100000 !important;
}

.toggleDropdown {
  position: relative;
  z-index: 10000;
  /* background: #02559e; */
  padding: 10px;
  /* border-radius: 5px; */
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}

.focusArrow {
  position: absolute;
  z-index: 200000;
  top: 138%;
  left: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: bounce 1s infinite alternate;
  -webkit-animation: bounce 1s infinite alternate;
}

.focusText {
  color: #ffffff;
  width: 12rem;
  text-align: center;
}

@keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-15px);
  }
}
@-webkit-keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-15px);
  }
}
