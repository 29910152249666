.dialog {
  padding: 2rem 1.5rem 1.25rem !important;
}

.title {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0 0 2rem;
}

.actionButton {
  margin: 1.5rem 0 !important;
  padding: 0.875rem 0 !important;
  font-weight: 500 !important;
  width: 100% !important;
}

.input {
  width: 100% !important;
  margin-bottom: 1.5rem !important;
}

.rowForFlex {
  display: flex !important;
}

.flexItem {
  flex: 6;
  padding-right: 15px;
}

.mapContainer {
  min-height: 300px;
}
