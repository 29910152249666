.container{
    display: flex;
    align-items: center;
    /* position:relative; */
    min-width: max-content;
}

.altContainer{
    border-right: 1px solid #C3CFDC;
    display: flex;
    align-items: center;
    position:relative;
}

.categoryTitle{
    margin: 0rem !important;
    padding: 10px 20px;
    min-width: 5rem;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
}

.dropdown, .altDropdown{
    position: absolute;
    top:100%;
    background: #ffffff;
    -webkit-box-shadow: -2px 5px 7px 2px rgba(0,0,0,0.25); 
    box-shadow: -2px 5px 7px 2px rgba(0,0,0,0.25);
    z-index: 1000;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding:10px
}

.dropdown{
    width:30rem;
    display: none;
}

.altDropdown{
    min-width:41rem;
    display: none;
}

.categoryListing, .altCategoryListing{
    display: flex;
    padding:10px
}

.categoryListing{
    min-width: 28rem;
}

.altCategoryListing{
    min-width:38rem;
}

.categoryListingContainer{
    flex:6;
    min-width:8.5rem;
    height:23rem;
    margin-right: 1rem;
    /* overflow-y: auto; */
}
.altCategoryListingContainer{
    flex:6;
    min-width:10rem;
    height:23rem;
    margin-right: 1rem;
    /* overflow-y: auto; */
}

.categoryListingContainer h3, .altCategoryListingContainer h3{
    margin: 1rem 0 !important;
    min-width: 5rem;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
}

.categoryListingContainer:last-child, .altCategoryListingContainer:last-child {
    margin-right: 0;
  }

.listing{
    height:20rem;
    overflow-y: hidden;
}

.listingItem{
   cursor: pointer;
   /* border:1px solid red; */
}

.container:hover .dropdown, .altContainer:hover .altDropdown {display: block;}


.listing:hover {
    overflow-y: auto;
  }
  
  .listing::-webkit-scrollbar {
    width: 5px;
    height: 4px;
    background-color: rgba(105, 119, 140, 0.5);
    display: block;
  }
  .listing::-webkit-scrollbar-thumb {
    background-color: rgba(0, 85, 158, 0.5);
  }