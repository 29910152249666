 /* first input */
.containerAlt {
  height: 2rem;
  box-sizing: border-box;
  overflow: hidden;
  min-width: 8rem;
}

.quantityInput {
  min-width: 2rem !important;
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #F3F3F7;
  border-radius: 6px;
  padding-left: 5px;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.1px;
}

.quantityInput::placeholder {
 color: var(--disabled-text);
 font-family: 'Avenir';
 font-style: normal;
 font-weight: 500;
 font-size: 15px;
 line-height: 18px;
 letter-spacing: 0.1px;
}

.quantityInput:focus {
  outline: none;
}

.buttonAlt {
  height: 100%;
  border: none;
  margin-left: 5px;
  background: #E2EEFF;
  border-radius: 6px;
  min-width: 3rem;
  font-family: 'Avenir';
  font-weight: 500;
  color: var(--disabled-text);
  padding: 0px 12px;
  font-size: 15px;
  line-height: 18px;
}

.buttonAlt:focus {
  color: var(--primary);
}

/* qty change */
.container {
  height: 2rem;
  box-sizing: border-box;
  min-width: 8rem;
  overflow: hidden;
}

.containerAlt:focus-within .buttonAlt {
  color: var(--primary);
}

.button {
  flex: 1;
  border: none;
  color: var(--primary);
  max-width: 30%;
  background: #E2EEFF;
  height: 100%;
  cursor: pointer;
  border-radius: 6px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-family: 'Avenir';
}

.button:first-of-type {
  color: #E61D17;
}


.disabledButton {
  color: #b5cde3;
}

.button:disabled, .buttonAlt:disabled{
  background: #E2E4F0;
 color: #8991AC;
}

.quantity {
  background: white;
  min-width: 40% !important;
  border: 0px !important;
  outline: none !important;
  text-align: center;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
}

.quantity:disabled {
 color: #8991AC;
}

.button,
.buttonAlt:active:enabled {
  background-position: center;
  transition: background 0.8s;
}

@media (hover: hover) {
.button:hover:enabled
 {
   border: 1px solid rgba(0, 85, 158, 0.1);
  background: transparent radial-gradient(circle, transparent 1%, transparent 1%) center/15000%;
 }
}

.button:active:enabled,
.buttonAlt:active:enabled {
  background-color: rgba(0, 85, 158, 0.1);
  background-size: 100%;
  transition: background 0s;
}