.container{
    background:#D9E5F0;
    display:flex; 
    position: relative;
}

.categories{
    display:flex;
    overflow-x: hidden;
}

.containerTitle{
    margin:0rem !important;
    padding:10px 10px;
    width:12rem;
    text-transform: uppercase;
    color: #02559E;
    font-weight:bold;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.dropdownIcon{
    margin-left:10px;
    padding-bottom:5px
}

.categories:hover {
  overflow-x: auto;
}

.scrollIndicator{
  display: none;
  padding-top: 0.5rem;
  min-width: 12px;
  min-height: 12px;
}

.container:hover .scrollIndicator {
display:block;
}

