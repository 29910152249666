.backButton {
    cursor: pointer;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    justify-content: center;
    box-shadow: 0px 5px 16px -4px rgba(29, 28, 28, 0.1);
    border-radius: 50%;
}
.buttonSize{
    height: 18px;
}

.backButton:active {
    background-color: #EEEEEE;
    background-size: 100%;
    background-position: center;
    transition: background 0.8s;
  }
