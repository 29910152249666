
.container{
    background:#02559E;
    color:#ffffff;
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding:12px;
    min-width:98vw;
}

.alerzoLogo{
    cursor:pointer;
    display: flex;
    flex:1;
    justify-self: flex-start;
    padding-right: 1em;
}

.headerEnd{
    padding-left:30px;
    display: flex;
    flex:3;
    justify-content: flex-end;
    align-items: stretch;
}

@media only screen and (max-width: 768px) {
    .container, .categoryContainer {
        display: none !important;
    }
}