.container {
  max-width: 80vw;
  margin: 20px auto;
  border: 1px solid #e2e4f0;
  padding: 5px;
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.container > div > div > div:nth-child(2) {
  display: none;
}

.container > div:first-of-type {
  flex: 11.5;
}

.icon {
  flex: 0.5;
  display: block;
  width: 20px;
  margin-top: 3px;
  padding-left: 10px;
}

.container > div > div {
  border: none;
}

@media only screen and (max-width: 768px) {
  .container > div:first-of-type > div:last-of-type {
    width: 106%;
    left: -5% !important;
    box-shadow: none !important;
  }
}

@media only screen and (min-width: 769px) {
  .container > div:first-of-type > div:last-of-type {
    width: 106%;
    left: -5% !important;
  }
}
