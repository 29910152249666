.pdpContainer {
  background: #E2EEFF;
  border-radius: 5px;
  display: flex;
  padding: 8px;
  align-self: flex-end;
  border: none;
  z-index: 200;
  margin-top: -38px;
  align-items: center;
  cursor: pointer;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #1A51A3;
}

.container {
z-index: 200;
display: flex;
border: none;
background: #E2EEFF;
border-radius: 6px;
padding: 8px;
cursor: pointer;
font-family: 'Avenir';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 18px;
color: #1A51A3;
}

.pdpContainer span , .container span {
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap;
}

.notifyPrompt {
  flex: 1 1;
  text-align: center;
}

@media only screen and (max-width: 280px) {
  .pdpContainer{
margin-top: 0; 
align-self: flex-start;
 }
}
